<template>
  <div class="container">
    <PublicHeader :totalName="$t('Accesslog').title" :routerName="routerName" />
    <div class="nav">
      <div
        class="nav-item"
        v-for="(nItem, nIndex) in NavList"
        :key="nIndex"
        :class="{ active: activeIndex === nIndex }"
        @click="selectNav(nIndex, nItem.type)"
      >
        {{ nItem.title }}
      </div>
    </div>
    <van-pull-refresh
      v-model="refreshing"
      :pulling-text="$t('public').pullingText"
      :loosing-text="$t('public').ReleaseText"
      :loading-text="$t('public').loadingText"
      :success-text="$t('public').RefreshSucceeded"
      @refresh="onRefresh"
    >
      <!-- <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
      >
        <van-cell v-for="item in list" :key="item" :title="item" />
      </van-list> -->
      <van-list
        class="list-box"
        ref="list"
        v-model="loading"
        :finished="finished"
        :loading-text="$t('public').loadingText"
        :finished-text="$t('public').noMoreData"
        @load="getList"
      >
        <van-row class="title" type="flex">
          <van-col span="6" v-for="(item, index) in titleList" :key="index">
            {{ item }}
          </van-col>
        </van-row>
        <template v-for="(lItem, lIndex) in list">
          <van-row :key="lIndex" class="list-item" type="flex">
            <van-col span="6">
              {{ lItem.Amount }}
            </van-col>
            <van-col span="6">
              {{
                form.isUpper
                  ? $t('Accesslog').Inpour
                  : $t('Accesslog').Withdrawal
              }}
            </van-col>
            <van-col span="6">
              {{ lItem.CreateTime }}
            </van-col>
            <van-col
              span="6"
              :class="{
                success: lItem.TransferStatus === 'Success',
                fail: lItem.TransferStatus === 'Fail',
              }"
            >
              {{ $t('statusLang')[lItem.TransferStatus] }}
            </van-col>
          </van-row>
          <van-row :key="lIndex">
            <p v-if="lItem.TransferStatus === 'Fail'" class="psty">
              {{ lItem.ExamineRemark }}
            </p>
          </van-row>
        </template>
      </van-list>
    </van-pull-refresh>
  </div>
</template>

<script>
import PublicHeader from '@/components/publicHeader'
import transactionApi from '@/api/transaction'
import { TransferStatusArr } from '@/utils/validate'
export default {
  components: {
    PublicHeader,
  },
  data() {
    return {
      activeIndex: 0,
      TransferStatusArr,
      routerName: 'Me',
      NavList: [
        {
          title: this.$t('Accesslog').Inpour,
        },
        {
          title: this.$t('Accesslog').Withdrawal,
        },
      ],
      titleList: [
        this.$t('Accesslog').Amount,
        this.$t('Accesslog').type,
        this.$t('Accesslog').Time,
        this.$t('Accesslog').Status,
      ],
      list: [
        // {
        //   Amount: '200',
        //   Type: 'USDT',
        //   CreateTime: '2020-02-01',
        //   TransferStatus: 'Success',
        //   ExamineRemark: 'sadfasfd'
        // },
        // {
        //   Amount: '200',
        //   Type: 'USDT',
        //   CreateTime: '2020-02-01',
        //   TransferStatus: 'Success',
        //   ExamineRemark: 'sadfasfd'
        // },
        // {
        //   Amount: '200',
        //   Type: 'USDT',
        //   CreateTime: '2020-02-01',
        //   TransferStatus: 'Fail',
        //   ExamineRemark: 'sadfasfd'
        // },
        // {
        //   Amount: '200',
        //   Type: 'USDT',
        //   CreateTime: '2020-02-01',
        //   TransferStatus: 'Fail',
        //   ExamineRemark:
        //     '睡觉了空间上的芬兰航空微软六九的离开家省得浪费空间士大夫为了看人家老师的课积分'
        // },
        // {
        //   Amount: '200',
        //   Type: 'USDT',
        //   CreateTime: '2020-02-01',
        //   TransferStatus: 'Success',
        //   ExamineRemark: 'sadfasfd'
        // },
        // {
        //   Amount: '200',
        //   Type: 'USDT',
        //   CreateTime: '2020-02-01',
        //   TransferStatus: 'Success',
        //   ExamineRemark: 'sadfasfd'
        // }
      ],
      form: {
        isUpper: true,
        page: 1,
        size: 10,
      },
      loading: false,
      finished: false,
      refreshing: false,
    }
  },
  created() {},
  methods: {
    selectNav(index) {
      this.activeIndex = index
      this.form.isUpper = index == 0 ? true : false
      this.onRefresh()
    },
    async getList() {
      const res = await transactionApi.getRecords(this.form)
      let data = res.Data
      let length = data.length
      if (length != 0) {
        this.list.push.apply(this.list, data)
        this.form.page++
      }
      if (length !== this.form.size) {
        this.finished = true
      }
      if (this.refreshing) {
        this.refreshing = false
      }
      this.loading = false
    },
    onRefresh() {
      this.form.page = 1
      this.finished = false
      this.loading = true
      setTimeout(() => {
        this.list = []
        this.getList()
      }, 100)
    },
  },
}
</script>

<style lang="stylus" scoped>
@import "~@/styles/mixin.styl";
.container
  width: 100%;
  background-color #1f1f1f
  font-size: 14px
  color #ebe5cd
  overflow-x hidden
  height 100vh
  padding-top 48.5px
  text-align center
  .nav
    height 30px
    margin 15px
    border-radius 2px
    border 1px solid #FAE39C
    display flex
    color #FAE39C
    &-item
      flex 1
      color #FAE39C
      flex-center()
    .active
      background #FAE39C
      color #000
  .title
    font-size 13px
    height 37px
    .van-col
      line-height 37px
  .list-box
    height calc(100vh - 117px)
    overflow scroll
  .list-item
    height 44px
    font-size 12px
    color #747269
    .van-col
      flex-center()
    .fail
      color #ff0000
    .success
      color #fae39c
</style>
<style>
.psty {
  padding-left: 0.35rem;
  padding-right: 0.2rem;
  text-align: left;
  line-height: 0.2rem;
  box-sizing: border-box;
}
</style>
